<template>
  <div>
    <div class="tab">
      <div :class="active == 0 ? 'item active' : 'item'">我要提问</div>
    </div>
    <writetime type="6"></writetime>
    <div class="content">
      <textarea
        v-model="val"
        class="txt"
        name=""
        id=""
        cols="30"
        rows="10"
        placeholder="请输入您所要提问的问题，我们的老师会为您解答"
      ></textarea>
    </div>
    <div class="btn" @click="submit">完成提问></div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      active: 0,
      val: "",
    };
  },
  computed: {
    ...mapState({
      classid: (state) => state.classid,
      userInfo: (state) => state.userInfo,
    }),
  },
  methods: {
    ...mapActions(["getMinePutQuestion", "getinsAsking_x"]),
    // 添加问题
    submit() {
      if (this.val.trim() == "") {
        this.$message({
          message: "请输入提问问题",
          type: "warning",
        });
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "提交中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.getinsAsking_x({
        student_id: this.userInfo.id, //学员id
        class_id: this.classid, //班级id
        question: this.val, //问题
      }).then((res) => {
        loading.close();
        this.$message({
          message: res.message,
          type: res.successCode == 200 ? "success" : "cancel",
        });
        if (res.successCode == 200) {
          this.$router.back();
        }
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.tab {
  display: flex;
  height: 77px;
  line-height: 77px;
  justify-content: space-around;
  border-bottom: 1px solid #e1e1e1;
  .item {
    cursor: pointer;
    position: relative;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #a7a7a7;
  }
  .active {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
    &::before {
      position: absolute;
      display: inline-block;
      content: "";
      width: 56px;
      height: 4px;
      background: #137cfb;
      bottom: -3px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.content {
  margin-top: 65px;
  padding: 0 34px;
  box-sizing: border-box;
  .txt {
    resize: none;
    border: 0px none;
    outline: none;
    border-bottom: 1px solid #e1e1e1;
    width: 100%;
    height: 100px;
    ::placeholder {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #137cfb;
    }
  }
}
.btn {
  cursor: pointer;
  margin-top: 49px;
  margin-left: 34px;
  color: #fff;
  text-align: center;
  line-height: 33px;
  width: 116px;
  height: 33px;
  background: #137cfb;
  border-radius: 1px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
</style>